import { useState } from "react";

import { errorHandling } from "../../services/errorHandling";
import { api } from "../../lib/axios";

import { Input } from "../../components/Forms/Input";
import { Button } from "../../components/Items/Button";
import { CardLoose } from "../../components/CardLoose";

import LogoSicma from "../../assets/images/logo_sicma.png";

export const Login = () => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [showPass, setShowPass] = useState(false);

   const login = async (e) => {
      e.preventDefault();

      try {
         const response = await api.post('/auth/login', { email, password });

         const responseApi = response.data.response;
         localStorage.setItem('token', responseApi.token);

         if ([0, 1, "0", "1"].includes(responseApi.user.level) > 0)
            window.location = '/gestao';
         else if ([2, "2"].includes(responseApi.user.level) > 0)
            window.location = '/funcionario';

      } catch (error) {
         errorHandling(error);
      }
   }

   return (
      <CardLoose>
         <div className="text-center">
            <h1 className="h4 text-gray-900 mb-4 font-weight-bold">
               <img src={LogoSicma} alt="Logo do SiCMA" style={{ maxHeight: '50px' }} />
            </h1>
         </div>

         <div className="row">
            <Input
               type="email"
               placeholder="E-mail de acesso..."
               value={email}
               onChange={(e) => setEmail(e.target.value)}
            />

            <Input
               type={showPass ? 'text' : 'password'}
               placeholder="Sua senha"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               group={true}
               groupIcon={showPass ? "fas fa-eye" : "fas fa-eye-slash"}
               groupClick={() => setShowPass(!showPass)}
            />

            <div className="col-12">
               <Button
                  text="Login"
                  color="primary"
                  col="12"
                  otherClass="btn-block"
                  onClick={(e) => login(e)}
                  disabled={!email || !password}
               />
            </div>
         </div>

         <hr />

         <div className="text-center">
            <a className="small" href="/recuperar-senha">Esqueceu sua senha?</a>
         </div>
      </CardLoose>
   );
}