import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const errorHandling = (error) => {
   const response = error.response ? error.response : error;

   if (response.status) {
      const message = response.data.message || "";
      const codeStatus = Number(response.request.status);

      // response.status
      if (codeStatus === 200 || codeStatus === 201)
         toast.success(message, { autoClose: 2000 })
      else
         toast.error(message, { autoClose: 2000 })

      if (codeStatus === 403 && localStorage.getItem('token')) {
         localStorage.removeItem("token");
         window.location = '/';
      }
   } else {
      toast.error('Erro ao realizar requisição', { autoClose: 2000 })
   }
}