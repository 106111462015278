import { useState, useEffect } from "react";
import { reqApi } from "../../lib/axios";

import { EmployeeTemplate } from "./EmployeeTemplate";

import { SectionPage } from "../../components/Page/SectionPage";
import { TitlePage } from "../../components/Page/TitlePage";

import { Table } from "../../components/Table/Table";
import { RowTable } from "../../components/Table/RowTable";
import { ColTable } from "../../components/Table/ColTable";

import { Button } from "../../components/Items/Button";

import { errorHandling } from "../../services/errorHandling";

export const EmployeeTargets = () => {
   const [loading, setLoading] = useState(true);

   const [listTargets, setListTargets] = useState([]);

   const managerActionsForTarget = (id) => {
      window.location = `/funcionario/metas/${id}`;
   }

   // LISTA TODAS AS METAS DO SETOR DO FUNCIONÁRIO
   const getTargets = async () => {
      try {
         setLoading(true);
         
         const userToken = localStorage.getItem("token");

         const response = await reqApi("get", "targets", userToken);

         setListTargets(response.data.targets);

         setLoading(false);
      } catch (error) {
         errorHandling(error);
      }
   }

   useEffect(() => {
      getTargets();
   }, []);

   return (
      <EmployeeTemplate loading={loading}>
         <TitlePage>Gerenciar Etapas</TitlePage>

         <SectionPage>
            <Table
               cols={["Título da Meta", ""]}
               isRows={listTargets?.length > 0}
            >
               {listTargets.map((e, i) => (
                  <RowTable key={i}>
                     <ColTable>{e.title}</ColTable>
                     <ColTable>
                        <div className="row justify-content-end">
                           <Button
                              color="success"
                              icon="fa-eye"
                              text="Ver Etapas"
                              title="Ver Etapas"
                              size="btn-sm"
                              onClick={() => managerActionsForTarget(e.id)}
                           />
                        </div>
                     </ColTable>
                  </RowTable>
               ))}
            </Table>
         </SectionPage>
      </EmployeeTemplate>
   );
}