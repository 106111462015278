import { Template } from "../../components/Template";

export const EmployeeTemplate = ({ loading = false, children }) => {
   const routes = [
      {
         title: "Home",
         icon: "fa-home",
         route: "/funcionario"
      },
      {
         title: "Metas",
         icon: "fa-check-square",
         route: "/funcionario/metas"
      }
   ];

   return (
      <Template 
         routes={routes}
         loading={loading}
      >
         {children}
      </Template>
   )
}