import { useState } from "react";
import { Actions } from "../Others/Actions";

export const ManagerActions = () => {
   const [loading, setLoading] = useState(true);

   return (
      <Actions
         level="manager"
         loading={loading}
         setLoading={setLoading}
      />
   );
}