import { Button } from "../Items/Button";

export const ModalBody = ({ id_modal, title, buttonAction, disabledAction = false, hideAction = false, headerColor = null, children }) => {
   return (
      <div
         id={id_modal}
         className="modal fade"
         tabIndex="-1"
         role="dialog"
         aria-labelledby={`${id_modal}_LongTitle`}
         aria-hidden="true"
      >
         <div className="modal-dialog" role="document">
            <div className="modal-content">
               <div className={`modal-header ${headerColor ? `bg-${headerColor} text-light` : null}`}>
                  <h5
                     className="modal-title font-weight-bold"
                     id={`${id_modal}_LongTitle`}
                  >
                     {title}
                  </h5>

                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>

               <div className="modal-body">
                  <div className="row">
                     {children}
                  </div>
               </div>

               {!hideAction ?
                  <div className="modal-footer">
                     <Button
                        color="secondary"
                        text="Cancelar"
                        icon="fa-times"
                        data-dismiss="modal"
                     />

                     <Button
                        color="primary"
                        icon="fa-check"
                        text="Salvar"
                        onClick={buttonAction}
                        disabled={disabledAction}
                     />
                  </div>
                  : null}
            </div>
         </div>
      </div>
   );
}