import uuid from 'react-uuid';

export const Input = ({ type, label, col, required = false, id = null, group = false, groupIcon = "", groupClick = {}, ...rest }) => {
   const inputId = uuid();

   return (
      <div className={`form-group col-${col || 12}`}>
         {label
            ? <label htmlFor={inputId}>{label}{required ? "*" : null}</label>
            : null}

         <div className="input-group mb-2">
            {group ?
               <div 
                  className="input-group-prepend" 
                  onClick={groupClick}
               >
                  <div className="input-group-text">
                     <i className={groupIcon}></i>
                  </div>
               </div>
               : null}

            <input
               id={id ? id : inputId}
               type={type}
               className="form-control"
               {...rest}
            />
         </div>
      </div>
   );
}