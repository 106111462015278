import { Button } from "../Items/Button";

export const ButtonRightPage = ({ text, icon, color, ...rest }) => {
   return (
      <div className="row">
         <div className="col">
            <Button
               color={color}
               icon={icon}
               text={text}
               {...rest}
            />
         </div>
      </div>
   );
}