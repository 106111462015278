import { useState } from "react";
import { api } from "../../lib/axios";
import { errorHandling } from "../../services/errorHandling";

import { Input } from "../../components/Forms/Input";
import { Button } from "../../components/Items/Button";
import { CardLoose } from "../../components/CardLoose";

import { emailValidate } from "../../services/validate";
import { toast } from "react-toastify";

import LogoSicma from "../../assets/images/logo_sicma.png";

export const ForgotPassword = () => {
   const [email, setEmail] = useState("");

   const sendEmail = async () => {
      try {
         if (!emailValidate(email)) {
            toast.error('E-mail inválido!');
         } else {
            await api.post('/auth/resetpassword', { email });
            toast.success('Senha atualizada para a padrão do sistema!');
         }
      } catch (error) {
         errorHandling(error);
      }
   }

   return (
      <CardLoose>
         <div className="text-center">
            <h1 className="h4 text-gray-900 mb-4 font-weight-bold">
               <img src={LogoSicma} alt="Logo do SiCMA" style={{ maxHeight: '50px' }} />
            </h1>
         </div>

         <div className="row">
            <Input
               type="email"
               placeholder="Digite seu e-mail..."
               value={email}
               onChange={(e) => setEmail(e.target.value)}
            />

            <div className="col-12">
               <Button
                  text="Enviar"
                  color="primary"
                  col="12"
                  otherClass="btn-block"
                  onClick={sendEmail}
                  disabled={!email}
               />
            </div>
         </div>

         <hr />

         <div className="text-center">
            <a className="small" href="/">Já possui conta? Faça Login</a>
         </div>
      </CardLoose>
   );
}