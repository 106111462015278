import uuid from 'react-uuid';
import { ColTable } from './ColTable';
import { RowTable } from './RowTable';

export const Table = ({ cols, isRows = true, children }) => {
   return (
      <div className='table-responsive-sm table-responsive-md'>
         <table className="table table-sm" key={uuid()}>
            <thead>
               <tr>
                  {cols.map((e) => <th key={uuid()}>{e}</th>)}
               </tr>
            </thead>

            <tbody>
               {isRows ?
                  children :
                  <RowTable>
                     <ColTable colSpan={cols.length}>Nenhum resultado encontrado</ColTable>
                  </RowTable>
               }
            </tbody>
         </table>
      </div>
   );
}  