import uuid from 'react-uuid';

// filter mode: troca um "selecionar" bloqueado por "todos"

export const Select = ({ label, col, options, optionsAttribute = ['id', 'title'], required = false, filterMode = false, optgroup = false, ...rest }) => {
   const selectId = uuid();

   return (
      <div className={`form-group col-${col || 12}`}>
         {label ? <label htmlFor={selectId}>{label}{required ? "*" : null}</label> : null}

         <select
            id={selectId}
            className="form-control"
            {...rest}
         >
            <option value="" disabled={!filterMode}>{filterMode ? "Todos" : "Selecionar:"}</option>

            {!optgroup ?
               options.map((opt, i) =>
                  <option
                     key={i}
                     value={opt[optionsAttribute[0]]}
                  >
                     {opt[optionsAttribute[1]]}
                  </option>
               )
               :
               options.map((opt, i) =>
                  <optgroup
                     key={i}
                     label={opt[optionsAttribute[1]]}
                     className={'bg-primary text-light'}
                  >
                     <option value={opt[optionsAttribute[0]]} className={'bg-light text-dark'}>
                        {opt[optionsAttribute[1]]} - Geral
                     </option>

                     {
                        opt[optionsAttribute[2]].map((opt_2, j) =>
                           <option
                              key={`${i}_${j}`}
                              value={opt_2[optionsAttribute[0]]}
                              className={'bg-light text-dark'}
                           >
                              {opt_2[optionsAttribute[1]]}
                           </option>
                        )
                     }
                  </optgroup>
               )
            }
         </select>
      </div>
   );
}