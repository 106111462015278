import { useEffect, useState } from "react";
import { reqApi } from "../../lib/axios";

import { ManagerTemplate } from "./ManagerTemplate";

import { SectionPage } from "../../components/Page/SectionPage";
import { TitlePage } from "../../components/Page/TitlePage";
import { ButtonRightPage } from "../../components/Page/ButtonRightPage";

import { Table } from "../../components/Table/Table";
import { RowTable } from "../../components/Table/RowTable";
import { ColTable } from "../../components/Table/ColTable";

import { Button } from "../../components/Items/Button";
import { Badge } from "../../components/Items/Badge";
import { Checkbox } from "../../components/Items/Checkbox";

import { Input } from "../../components/Forms/Input";
import { Select } from "../../components/Forms/Select";
import { RequiredMessage } from "../../components/Forms/RequiredMessage";

import { ModalBody } from "../../components/Modal/ModalBody";

import { errorHandling } from "../../services/errorHandling";
import { emailValidate } from "../../services/validate";
import { getFunction } from "../../services/helpers";

// NOTIFICAÇÕES
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const levels = [
   { id: 1, title: "Gestão" },
   { id: 2, title: "Funcionário" }
]

const defaultData = {
   id: null,
   name: "",
   email: "",
   level: "",
   sector_id: ""
}

const defaultFilterData = {
   search: "",
   sector_id: "",
   checkManager: true,
   checkEmployee: true
}

export const ManagerEmployees = () => {
   const [loading, setLoading] = useState(true);

   const [listUsers, setListUsers] = useState([]);
   const [listSectors, setListSectors] = useState([]);

   const [formData, setFormData] = useState(defaultData);
   const [filterData, setFilterData] = useState(defaultFilterData);

   const handleFormChange = (field, value, data = formData, setData = setFormData) => {
      const newForm = Object.assign({}, data, { [field]: value })
      setData(newForm);
   }

   const clearFields = () => {
      setFilterData({
         search: "",
         sector_id: "",
         checkManager: false,
         checkEmployee: false
      });
   }

   // LISTA TODOS OS FUNCIONÁRIOS
   const getEmployees = async () => {
      try {
         setLoading(true);

         const userToken = localStorage.getItem("token");

         let arrayLevel = [];

         if (filterData.checkManager) arrayLevel.push(1);
         if (filterData.checkEmployee) arrayLevel.push(2);

         const response = await reqApi("get", `users?search=${filterData.search}&level=${arrayLevel}&sector_id=${filterData.sector_id}`, userToken);

         setListUsers(response.data.users);

         setLoading(false);
      } catch (error) {
         errorHandling(error);

         setLoading(false)
      }
   }

   // LISTA TODOS OS SETORES
   const getSectors = async () => {
      try {
         const userToken = localStorage.getItem("token");

         const response = await reqApi("get", "sectors", userToken);

         setListSectors(response.data.sectors);
      } catch (error) {
         errorHandling(error);
      }
   }

   // ENVIA FORMULÁRIO DE CRIAÇÃO OU EDIÇÃO DO FUNCIONÁRIO
   const sendForm = async () => {
      try {
         if (!emailValidate(formData.email)) {
            toast.error('E-mail inválido!');
            return;
         }

         const userToken = localStorage.getItem("token");

         let response = {};

         if (!formData.id)
            response = await reqApi("post", "users", userToken, formData);
         else
            response = await reqApi("patch", `users/${formData.id}`, userToken, formData);

         errorHandling(response);

         document.getElementById('createEmployee').click();

         getEmployees();
         setFormData({ ...formData, ...defaultData });
      } catch (error) {
         errorHandling(error);
      }
   }

   // DELETA UM FUNCIONÁRIO
   const deleteEmployee = async (id) => {
      try {
         Swal.fire({
            title: 'Você tem certeza?',
            text: "O usuário será excluído!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: 'Cancelar'
         }).then(async (result) => {
            if (result.isConfirmed) {
               const userToken = localStorage.getItem("token");

               const response = await reqApi("delete", `users/${id}`, userToken);

               errorHandling(response);

               getEmployees();
            }
         });
      } catch (error) {
         errorHandling(error);
      }
   }

   // EDITAR UM FUNCIONÁRIO
   const editEmployee = async (employee) => {
      setFormData({
         id: employee.id,
         name: employee.name,
         email: employee.email,
         sector_id: employee.sector_id,
         level: employee.level
      });
   }

   useEffect(() => {
      getEmployees();
      getSectors();

      // eslint-disable-next-line
   }, []);

   return (
      <ManagerTemplate loading={loading}>
         <TitlePage>Gerenciar Funcionários</TitlePage>

         <SectionPage>
            <ButtonRightPage
               text="Novo Funcionário"
               icon="fa-plus"
               color="success"
               data-toggle="modal"
               data-target="#createEmployee"
               id="btnCreateEmployee"
               onClick={() => setFormData({ ...formData, ...defaultData })}
            />
         </SectionPage>

         <hr />

         <SectionPage>
            <span className="font-weight-bolder">Filtros de Busca:</span>

            <div className="row mt-2">
               <Input
                  type="text"
                  label="Nome ou Email"
                  placeholder="Pesquisar..."
                  value={filterData.search}
                  onChange={(e) => handleFormChange("search", e.target.value, filterData, setFilterData)}
                  col="lg-6 col-sm-12"
                  className="form-control"
               />

               <Select
                  label="Setor"
                  col="lg-6 col-sm-12"
                  options={listSectors}
                  value={filterData.sector_id}
                  onChange={(e) => handleFormChange("sector_id", e.target.value, filterData, setFilterData)}
                  filterMode={true}
               />

               <div className="col-lg-8 col-sm-12 mb-2">
                  <Checkbox
                     text="Gestão"
                     checked={filterData.checkManager}
                     onChange={() => handleFormChange("checkManager", !filterData.checkManager, filterData, setFilterData)}
                  />

                  <Checkbox
                     text="Funcionário"
                     checked={filterData.checkEmployee}
                     onChange={() => handleFormChange("checkEmployee", !filterData.checkEmployee, filterData, setFilterData)}
                  />
               </div>

               <div className="col-lg-4 col-sm-12 mb-2 text-lg-right text-sm-left">
                  <Button
                     color="primary"
                     icon="fa-search"
                     text="Pesquisar"
                     otherClass="btn-sm"
                     onClick={getEmployees}
                  />

                  <Button
                     color="danger"
                     icon="fa-eraser"
                     text="Limpar Filtros"
                     otherClass="btn-sm"
                     onClick={clearFields}
                  />
               </div>
            </div>
         </SectionPage>

         <SectionPage>
            <Table
               cols={["Nome", "Nível", "Setor", "Email", ""]}
               isRows={listUsers?.length > 0}
            >
               {listUsers.map((e, i) => (
                  <RowTable key={i}>
                     <ColTable>{e.name}</ColTable>
                     <ColTable>{getFunction(e.level)}</ColTable>
                     <ColTable>{e.sector || <Badge color="warning">Não definido</Badge>}</ColTable>
                     <ColTable>{e.email}</ColTable>
                     <ColTable>
                        <div className="row justify-content-end">
                           <Button
                              color="warning"
                              icon="fa-edit"
                              title="Editar"
                              size="btn-sm"
                              data-toggle="modal"
                              data-target="#createEmployee"
                              onClick={() => editEmployee(e)}
                           />

                           <Button
                              color="danger"
                              icon="fa-times"
                              title="Remover"
                              size="btn-sm"
                              onClick={() => deleteEmployee(e.id)}
                           />
                        </div>
                     </ColTable>
                  </RowTable>
               ))}
            </Table>
         </SectionPage>

         <ModalBody
            id_modal="createEmployee"
            title="Cadastrar Funcionário"
            buttonAction={sendForm}
            disabledAction={!(formData.name && formData.email && formData.level)}
         >
            <Input
               type="text"
               label="Nome"
               value={formData.name}
               onChange={(e) => handleFormChange("name", e.target.value)}
               required={true}
            />

            <Input
               type="email"
               label="Email"
               value={formData.email}
               onChange={(e) => handleFormChange("email", e.target.value)}
               required={true}
            />

            <Select
               label="Setor"
               col="6"
               options={listSectors}
               value={formData.sector_id}
               onChange={(e) => handleFormChange("sector_id", e.target.value)}
            />

            <Select
               label="Nível"
               col="6"
               options={levels}
               value={formData.level}
               onChange={(e) => handleFormChange("level", e.target.value)}
               required={true}
            />

            <RequiredMessage />
         </ModalBody>
      </ManagerTemplate>
   );
}