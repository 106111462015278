import jwt_decode from 'jwt-decode';

export const NavBar = ({ toogleMenu }) => {
   const token = localStorage.getItem("token");
   const tokenDecode = jwt_decode(token);

   const userName = tokenDecode.name || "Desconhecido";
   const userLevel = tokenDecode.level || null;
   
   let link = "";

   if ([0, 1].includes(Number(userLevel))) link = process.env.REACT_APP_FILE_MANUAL_GESTAO;
   else if ([2].includes(Number(userLevel))) link = process.env.REACT_APP_FILE_MANUAL_FUNCIONARIO;

   return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
         {/* ABRIR/FECHAR MENU */}
         <button 
            id="sidebarToggleTop" 
            className="btn btn-link rounded-circle mr-3"
            onClick={toogleMenu}
         >
            <i className="fa fa-bars"></i>
         </button>

         <ul className="navbar-nav ml-auto">
            <div className="topbar-divider"></div>

            {/* PERFIL / SAIR */}
            <li className="nav-item dropdown no-arrow">
               <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
               >
                  <span className="mr-2 text-dark" style={{fontSize: '14px'}}>{userName}</span>
                  <i className="fas fa-angle-down text-dark"></i>
               </a>

               <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <a className="dropdown-item" href="/perfil">
                     <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i> Perfil
                  </a>

                  { link ? 
                     <a className="dropdown-item" href={link} download="manual.pdf">
                        <i className="fas fa-download fa-sm fa-fw mr-2 text-gray-400"></i> Manual
                     </a>
                  : false }

                  <a className="dropdown-item" href="/sair">
                     <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Sair
                  </a>
               </div>
            </li>
         </ul>
      </nav >
   );
}