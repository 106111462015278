import { TitleSite } from "./TitleSite";

export const Menu = ({ routes, otherClass = '' }) => {   
   return (
      <ul 
         id="accordionSidebar"
         className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${otherClass}`}
      >
         <TitleSite />

         <hr className="sidebar-divider my-0" />

         {
            routes.map((e, i) => {
               return (
                  <li className="nav-item" key={i}>
                     <a className="nav-link" href={e.route}>
                        <i className={`fas fa-fw ${e.icon}`}></i>
                        <span>{e.title}</span>
                     </a>
                  </li>
               )
            })
         }
      </ul>
   );
}