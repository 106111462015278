import { useState, useEffect } from "react";
import { reqApi } from "../../lib/axios";

import { ManagerTemplate } from "./ManagerTemplate";

import { SectionPage } from "../../components/Page/SectionPage";
import { TitlePage } from "../../components/Page/TitlePage";
import { ButtonRightPage } from "../../components/Page/ButtonRightPage";

import { Table } from "../../components/Table/Table";
import { RowTable } from "../../components/Table/RowTable";
import { ColTable } from "../../components/Table/ColTable";

import { Button } from "../../components/Items/Button";

import { ModalBody } from "../../components/Modal/ModalBody";
import { Input } from "../../components/Forms/Input";
import { Select } from "../../components/Forms/Select";
import { RequiredMessage } from "../../components/Forms/RequiredMessage";

// NOTIFICAÇÕES
import { errorHandling } from "../../services/errorHandling";
import Swal from "sweetalert2";
import { Badge } from "../../components/Items/Badge";
import { Checkbox } from "../../components/Items/Checkbox";

const defaultData = {
   id: null,
   title: "",
   sector_id: ""
}

const defaultFilterData = {
   sector_id: "",
   title: ""
}

export const ManagerTargets = () => {
   const [loading, setLoading] = useState(true);

   const [listTargets, setListTargets] = useState([]);
   const [listSectors, setListSectors] = useState([]);

   const [listSituations, setListSituations] = useState([]);
   const [filterSituations, setFilterSituations] = useState([]);

   const [formData, setFormData] = useState(defaultData);
   const [filterData, setFilterData] = useState(defaultFilterData);

   const handleFormChange = (field, value, data = formData, setData = setFormData) => {
      const newForm = Object.assign({}, data, { [field]: value })
      setData(newForm);
   }

   const listSituationsForFiler = (clear = false) => {
      let situationsArray = [];

      listSituations?.forEach(e => {
         situationsArray.push(e.id);
      });

      setFilterSituations(!clear ? situationsArray : []);
   }

   const clearFields = () => {
      setFilterData(defaultFilterData);
      listSituationsForFiler(true);
   }

   // LISTA TODAS AS METAS
   const getTargets = async () => {
      try {
         setLoading(true);

         const userToken = localStorage.getItem("token");

         const url = `targets?situations=${filterSituations}&sector_id=${filterData.sector_id}&title=${filterData.title}`;

         const response = await reqApi("get", url, userToken);

         setListTargets(response.data.targets);

         setLoading(false);
      } catch (error) {
         errorHandling(error);
         setLoading(false);
      }
   }

   // LISTA TODAS AS SITUAÇÕES
   const getSituations = async () => {
      try {
         const userToken = localStorage.getItem("token");

         const response = await reqApi("get", "situations", userToken);

         setListSituations(response.data.situations);
      } catch (error) {
         errorHandling(error);
      }
   }

   // LISTA TODOS OS SETORES
   const getSectors = async () => {
      try {
         const userToken = localStorage.getItem("token");

         const response = await reqApi("get", "sectors", userToken);

         setListSectors(response.data.sectors);
      } catch (error) {
         errorHandling(error);
      }
   }

   // ENVIA FORMULÁRIO DE CRIAÇÃO OU EDIÇÃO DO FUNCIONÁRIO
   const sendForm = async () => {
      try {
         const userToken = localStorage.getItem("token");

         let response = {};

         if (!formData.id)
            response = await reqApi("post", "targets", userToken, formData);
         else
            response = await reqApi("patch", `targets/${formData.id}`, userToken, formData);

         errorHandling(response);

         document.getElementById('createTarget').click();

         getTargets();
         setFormData({ ...formData, ...defaultData });
      } catch (error) {
         errorHandling(error);
      }
   }

   // DELETA UM FUNCIONÁRIO
   const deleteTarget = async (id) => {
      try {
         Swal.fire({
            title: 'Você tem certeza?',
            text: "A meta e suas etapas serão excluídas permanentemente!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: 'Cancelar'
         }).then(async (result) => {
            if (result.isConfirmed) {
               const userToken = localStorage.getItem("token");

               const response = await reqApi("delete", `targets/${id}`, userToken);

               errorHandling(response);

               getTargets();
            }
         });
      } catch (error) {
         errorHandling(error);
      }
   }

   // EDITAR UM FUNCIONÁRIO
   const editTarget = async (target) => {
      setFormData({
         id: target.id,
         title: target.title,
         sector_id: target.sector_id
      });
   }

   // DETALHES DE UMA META
   const managerActionsForTarget = (id) => {
      window.location = `/gestao/metas/${id}`;
   }

   useEffect(() => {
      getTargets();
      getSectors();
      getSituations();

      // eslint-disable-next-line
   }, [])

   return (
      <ManagerTemplate loading={loading}>
         <TitlePage>Gerenciar Metas</TitlePage>

         <SectionPage>
            <ButtonRightPage
               text="Nova Meta"
               icon="fa-plus"
               color="success"
               data-toggle="modal"
               data-target="#createTarget"
               id="btnCreateTarget"
               onClick={() => setFormData({ ...formData, ...defaultData })}
            />
         </SectionPage>

         <hr />

         <SectionPage>
            <span className="font-weight-bolder">Filtros de Busca:</span>

            <div className="row mt-2">
               <Input
                  type="text"
                  label="Nome da Meta"
                  value={filterData.title}
                  onChange={(e) => handleFormChange("title", e.target.value, filterData, setFilterData)}
                  col="lg-6 col-12"
               />

               <Select
                  label="Setor Responsável"
                  options={listSectors}
                  value={filterData.sector_id}
                  onChange={(e) => handleFormChange("sector_id", e.target.value, filterData, setFilterData)}
                  col="lg-6 col-12"
                  filterMode={true}
               />

               <div className="col-lg-8 col-sm-12 mb-2">
                  <Checkbox
                     text="Todas"
                     checked={filterSituations.includes(0)}
                     onChange={() => {
                        if (filterSituations.includes(0)) setFilterSituations(filterSituations.filter(n => n !== 0));
                        else setFilterSituations([...filterSituations, 0])
                     }}
                  />

                  {listSituations.map((e, i) => {
                     const inArray = filterSituations.includes(e.id);

                     return (
                        <Checkbox
                           key={i}
                           text={e.title}
                           checked={inArray}
                           onChange={() => {
                              if (inArray) setFilterSituations(filterSituations.filter(n => n !== e.id));
                              else setFilterSituations([...filterSituations, e.id])
                           }}
                        />
                     )
                  })}
               </div>

               <div className="col-lg-4 col-sm-12 mb-2 text-lg-right text-sm-left">
                  <Button
                     color="primary"
                     icon="fa-search"
                     text="Pesquisar"
                     otherClass="btn-sm"
                     onClick={getTargets}
                  />

                  <Button
                     color="danger"
                     icon="fa-eraser"
                     text="Limpar Filtros"
                     otherClass="btn-sm"
                     onClick={clearFields}
                  />
               </div>
            </div>
         </SectionPage>

         <SectionPage>
            <Table
               cols={["Meta", "Setor Responsável", "Status das Etapas", ""]}
               isRows={listTargets?.length > 0}
            >
               {listTargets.map((e, i) => (
                  <RowTable key={i}>
                     <ColTable>{e.title}</ColTable>
                     <ColTable>{e.sector}</ColTable>
                     <ColTable>
                        {e.progress ? <Badge otherClass='mr-1' color="warning">{e.progress}</Badge> : null}
                        {e.late ? <Badge otherClass='mr-1' color="danger">{e.late}</Badge> : null}
                        {e.canceled ? <Badge otherClass='mr-1' color="dark">{e.canceled}</Badge> : null}
                        {e.finished ? <Badge otherClass='mr-1' color="success">{e.finished}</Badge> : null}
                     </ColTable>
                     <ColTable>
                        <div className="row">
                           <div className="col text-right" style={{ minWidth: '180px' }}>
                              <Button
                                 color="info"
                                 icon="fa-arrow-right"
                                 title="Etapas da Meta"
                                 size="btn-sm"
                                 onClick={() => managerActionsForTarget(e.id)}
                              />

                              <Button
                                 color="warning"
                                 icon="fa-edit"
                                 title="Editar"
                                 size="btn-sm"
                                 data-toggle="modal"
                                 data-target="#createTarget"
                                 onClick={() => editTarget(e)}
                              />

                              <Button
                                 color="danger"
                                 icon="fa-times"
                                 title="Remover"
                                 size="btn-sm"
                                 onClick={() => deleteTarget(e.id)}
                              />
                           </div>
                        </div>
                     </ColTable>
                  </RowTable>
               ))}
            </Table>

            {
               listTargets?.length > 0 ?
                  <div className="row">
                     <div className="col-12"><small>Legenda:</small></div>
                     <div className="col-12">
                        <Badge otherClass='mr-1' color="warning">Em Andamento</Badge>
                        <Badge otherClass='mr-1' color="danger">Atrasada</Badge>
                        <Badge otherClass='mr-1' color="dark">Cancelada</Badge>
                        <Badge otherClass='mr-1' color="success">Finalizada</Badge>
                     </div>
                  </div>
                  : null
            }
         </SectionPage>

         <ModalBody
            id_modal="createTarget"
            title="Cadastrar Meta"
            buttonAction={sendForm}
            disabledAction={!(formData.title && formData.sector_id)}
         >
            <Input
               type="text"
               label="Nome da Meta"
               value={formData.title}
               onChange={(e) => handleFormChange("title", e.target.value)}
               required={true}
            />

            <Select
               label="Setor Responsável"
               options={listSectors}
               value={formData.sector_id}
               onChange={(e) => handleFormChange("sector_id", e.target.value)}
               required={true}
            />

            <RequiredMessage />
         </ModalBody>
      </ManagerTemplate>
   );
}