import WhiteLogoSicmaHead from "../assets/images/whitelogo_sicma_head.png";
import WhiteLogoSicmaText from "../assets/images/whitelogo_sicma_text.png";

export const TitleSite = () => {
   return (
      <span className="sidebar-brand d-flex align-items-center justify-content-center">
         <div className="sidebar-brand-icon rotate-n-15">
            <img src={WhiteLogoSicmaHead} alt="Logo do Sicma" style={{ maxHeight: '30px' }} />
         </div>
         <div className="sidebar-brand-text mx-3">
            <img src={WhiteLogoSicmaText} alt="Texto do logo do Sicma" style={{ maxHeight: '30px' }} />
         </div>
      </span>
   );
}