export const Button = ({ color, icon, title = "", text = "", otherClass = "", size = "", ...rest }) => {
   return (
      <button
         className={`btn ${size} btn-${color} mr-1 font-weight-bold ${otherClass}`}
         title={title}
         {...rest}
      >
         {icon ? <i className={`fas fa-fw ${icon}`}></i> : null} {text}
      </button>
   );
}