import uuid from 'react-uuid';

export const Checkbox = ({ value, text, checked = false, ...rest }) => {
   const inputId = uuid();

   return (
      <div className="form-check form-check-inline">
         <input 
            className="form-check-input" 
            type="checkbox"
            value={value} 
            checked={checked}
            id={inputId}
            key={inputId}
            {...rest}
         />
            <label className={`form-check-label`} htmlFor={inputId}>
               {text}
            </label>
      </div>
   );
}