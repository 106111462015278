import { Template } from "./Template";

const cols_default = "col-xl-5 col-lg-8 col-md-10 col-12";

export const CardLoose = ({ cols_card = cols_default, children }) => {
   return (
      <Template footer={false}>
         <div className="row justify-content-center">
            <div className={cols_card}>
               <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                     <div className="p-md-5 py-5 px-4">
                        {children}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Template>
   );
}