import { createBrowserRouter } from "react-router-dom";


import { Logout, RouteEmployee, RouteManager, RouteAuth, RouteNotLogged } from "./functions";

import { Login } from "../pages/Auth/Login";
import { ForgotPassword } from "../pages/Auth/ForgotPassword";
import { Profile } from "../pages/Auth/Profile";

import { ManagerEmployees } from "../pages/Manager/ManagerEmployees";
import { ManagerHome } from "../pages/Manager/ManagerHome";
import { ManagerTargets } from "../pages/Manager/ManagerTargets";
import { ManagerActions } from "../pages/Manager/ManagerActions";

import { EmployeeTargets } from "../pages/Employee/EmployeeTargets";
import { EmployeeHome } from "../pages/Employee/EmployeeHome";
import { EmployeeActions } from "../pages/Employee/EmployeeActions";

import { NotFound } from "../pages/Others/NotFound";

export const router = createBrowserRouter([
   { path: '/', element: <RouteNotLogged To={Login} /> },
   { path: '/sair', element: <Logout /> },
   { path: '/recuperar-senha', element: <RouteNotLogged To={ForgotPassword} /> },
   { path: '/perfil', element: <RouteAuth To={Profile} /> },

   { path: '/gestao', element: <RouteManager To={ManagerHome} /> },
   { path: '/gestao/funcionarios', element: <RouteManager To={ManagerEmployees} /> },
   { path: '/gestao/metas', element: <RouteManager To={ManagerTargets} /> },
   { path: '/gestao/metas/:id', element: <RouteManager To={ManagerActions} /> },

   { path: '/funcionario', element: <RouteEmployee To={EmployeeHome} /> },
   { path: '/funcionario/metas', element: <RouteEmployee To={EmployeeTargets} /> },
   { path: '/funcionario/metas/:id', element: <RouteEmployee To={EmployeeActions} /> },

   { path: '/*', element: <NotFound /> }
])