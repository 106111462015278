import { reqApi } from "../lib/axios";
import { Login } from "../pages/Auth/Login";
import jwt_decode from "jwt-decode";

export const RouteManager = ({ To }) => {
   try {
      if (validateAuth([0, 1])) return <To />;
      return <Login />;
   } catch (error) {
      return <Login />;
   }

}

export const RouteEmployee = ({ To }) => {
   try {
      if (validateAuth([2])) return <To />;
      return <Login />;
   } catch (error) {
      return <Login />;
   }
}

export const RouteAuth = ({ To }) => {
   try {
      if (validateAuth([0, 1, 2])) {
         const token = localStorage.getItem("token");
         const tokenDecode = jwt_decode(token);

         if ([0, 1].includes(Number(tokenDecode.level)))
            return <To level="manager" />;

         return <To level="employee" />;
      }

      return <Login />;
   } catch (error) {
      return <Login />;
   }
}

export const RouteNotLogged = ({ To }) => {
   const token = localStorage.getItem("token");

   if (token) {
      const tokenDecode = jwt_decode(token);

      if ([0, 1].includes(Number(tokenDecode.level)))
         window.location = '/gestao';
      if ([2].includes(Number(tokenDecode.level)))
         window.location = '/funcionario';
   }

   return <To />
}

export const Logout = () => {
   localStorage.removeItem("token");
   window.location = '/';
}

// FUNÇÕES VALIDADORAS
const validateAuth = (roles, goback = true) => {
   const userToken = localStorage.getItem("token");

   if (userToken) {
      const user = getUserByToken(userToken, roles, goback);
      if (user) return user;
   }

   return false;
}

const getUserByToken = async (userToken, roles = [], goback = true) => {
   const response = await reqApi("get", "auth/validate", userToken);

   if (response.data.user) {
      const level = Number(response.data.user.level);

      if (roles.includes(level)) {
         return true;
      } else {
         if (goback) window.location = '/';
         return;
      }
   }

   return response.data.user;
}