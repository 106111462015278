import { Template } from "../../components/Template";

export const ManagerTemplate = ({ loading = false, children }) => {
   const routes = [
      {
         title: "Home",
         icon: "fa-home",
         route: "/gestao"
      },
      {
         title: "Funcionários",
         icon: "fa-users",
         route: "/gestao/funcionarios"
      },
      {
         title: "Metas",
         icon: "fa-check-square",
         route: "/gestao/metas"
      }
   ];

   return (
      <Template 
         routes={routes}
         loading={loading}
      >
         {children}
      </Template>
   )
}