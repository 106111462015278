import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { reqApi } from "../../lib/axios";

import { SectionPage } from "../../components/Page/SectionPage";
import { TitlePage } from "../../components/Page/TitlePage";
import { ButtonRightPage } from "../../components/Page/ButtonRightPage";

import { Table } from "../../components/Table/Table";
import { RowTable } from "../../components/Table/RowTable";
import { ColTable } from "../../components/Table/ColTable";

import { Button } from "../../components/Items/Button";
import { Badge } from "../../components/Items/Badge";

import { ModalBody } from "../../components/Modal/ModalBody";
import { Input } from "../../components/Forms/Input";
import { Select } from "../../components/Forms/Select";
import { Textarea } from "../../components/Forms/Textarea";
import { RequiredMessage } from "../../components/Forms/RequiredMessage";
import { Span } from "../../components/Forms/Span";
import { Checkbox } from "../../components/Items/Checkbox";

import { errorHandling } from "../../services/errorHandling";
import { checkDate, dateBrConverter, sortFunction } from "../../services/helpers";

import { ManagerTemplate } from "../Manager/ManagerTemplate";
import { EmployeeTemplate } from "../Employee/EmployeeTemplate";

import Swal from "sweetalert2";

import moment from 'moment';
import { newTableForAlerts } from "../../services/html";

const today = moment().format('yyyy-MM-DD');

const defaultData = {
   id: null,
   title: "",
   sector_id: "",
   start_date: today,
   conclusion_date: today,
   days: 0,
   description: "",
   observations: "",
   situation_id: 1,
   situation_title: "Detalhes",
   situation_color: "light",
   updated_at: null
}

const defaultFilterData = {
   start_date: "",
   conclusion_date: "",
   sector_id: ""
}

export const Actions = ({ level, loading, setLoading }) => {
   const ChosenTemplate = level === "manager" ? ManagerTemplate : EmployeeTemplate;

   const { id } = useParams();

   const [listSectors, setListSectors] = useState([]);
   const [listSituations, setListSituations] = useState([]);
   const [selectSituations, setSelectSituations] = useState([]);

   const [filterSituations, setFilterSituations] = useState([]);
   const [filterData, setFilterData] = useState(defaultFilterData);

   const [target, setTarget] = useState({});
   const [formData, setFormData] = useState({
      ...defaultData,
      target_id: id
   });

   const [mode, setMode] = useState("create");

   // FUNCIONALIDADES ESTÁTICAS DA PÁGINA

   const handleFormChange = (field, value, data = formData, setData = setFormData) => {
      const newForm = Object.assign({}, data, { [field]: value })
      setData(newForm);
   }

   const daysChange = (days) => {
      if (days) {
         let start_date = new Date(formData.start_date);

         if (checkDate(start_date)) {
            const date_end = new Date(start_date.setDate(start_date.getDate() + parseInt(days) + 1));
            handleFormChange('conclusion_date', moment(date_end).format('yyyy-MM-DD'));
         }
      } else {
         handleFormChange('conclusion_date', formData.start_date);
      }
   }

   const listSituationsForFiler = (clear = false) => {
      let situationsArray = [];
      let situationsOptions = [];

      listSituations?.forEach(e => {
         situationsArray.push(e.id);

         // COMO PADRÃO, NÃO É DISPONÍVEL A OPÇÃO ATRASADO
         if (mode === "create" && e.id !== 2)
            situationsOptions.push(e);
      });

      setFilterSituations(!clear ? situationsArray : []);
      setSelectSituations(situationsOptions);
   }

   const clearFields = () => {
      setFilterData(defaultFilterData);
      listSituationsForFiler(true);
   }

   const checkDifferenceForInputs = () => {
      const dateEnd = new Date(formData.conclusion_date);
      const dateNow = new Date();

      // CASO A DATA FINAL SEJA ANTERIOR AO DIA DE HOJE É REMOVIDO A OPÇÃO "ANDAMENTO (1)" DO SELECT
      const diffDateNow = Math.ceil((dateEnd - dateNow) / (24 * 3600000));

      if (diffDateNow < 0)
         setSelectSituations(listSituations.filter(e => e.id !== 1));
      else
         setSelectSituations(listSituations.filter(e => e.id !== 2));
   }

   // CONSULTAS DA PÁGINA

   // LISTA TODOS OS SETORES (COM SUBSETORES)
   const getSectorsAndSubSectors = async () => {
      try {
         const userToken = localStorage.getItem("token");

         const response = await reqApi("get", "subsectors", userToken);

         setListSectors(response.data.sectors);
      } catch (error) {
         errorHandling(error);
      }
   }

   // LISTA TODAS AS SITUAÇÕES
   const getSituations = async () => {
      try {
         const userToken = localStorage.getItem("token");

         const response = await reqApi("get", "situations", userToken);

         setListSituations(response.data.situations);
      } catch (error) {
         errorHandling(error);
      }
   }

   // LISTA A META SELECIONADA
   const getOneTarget = async (notification = false) => {
      try {
         setLoading(true);

         const userToken = localStorage.getItem("token");

         const url = `targets/${formData.target_id}?situations=${filterSituations}&start_date=${filterData.start_date}&conclusion_date=${filterData.conclusion_date}&sector_id=${filterData.sector_id}`;

         const response = await reqApi("get", url, userToken);

         setTarget(response.data.target);

         if (notification) runAlerts(response.data.target.actions, response.data.target.date_now);

         setLoading(false);
      } catch (error) {
         errorHandling(error);

         setLoading(false)
      }
   }

   // DELETA UM FUNCIONÁRIO
   const deleteAction = async (id) => {
      try {
         Swal.fire({
            title: 'Você tem certeza?',
            text: "A etapa será excluída permanentemente!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: 'Cancelar'
         }).then(async (result) => {
            if (result.isConfirmed) {
               const userToken = localStorage.getItem("token");

               const response = await reqApi("delete", `targets/${formData.target_id}/actions/${id}`, userToken);

               errorHandling(response);

               getOneTarget();
            }
         });
      } catch (error) {
         errorHandling(error);
      }
   }

   // VISUALIZAR UMA ETAPA
   const showAction = async (action) => {
      setMode("show");

      const situationSelected = listSituations.filter(e => e.id === Number(action.situation_id));

      setFormData({
         id: action.id,
         target_id: action.target_id,
         title: action.title,
         sector_id: action.sector_id,
         start_date: action.start_date,
         conclusion_date: action.conclusion_date,
         description: action.description,
         observations: action.observations,
         situation_id: action.situation_id,
         situation_title: situationSelected[0].title,
         situation_color: situationSelected[0].color,
         updated_at: action.updated_at
      });

      document.getElementById('differenceDays').value = action.difference_days;
   }

   // EDITAR UMA ETAPA
   const editAction = async (action) => {
      setMode("edit");

      setFormData({
         id: action.id,
         target_id: action.target_id,
         title: action.title,
         sector_id: action.sector_id,
         start_date: action.start_date,
         conclusion_date: action.conclusion_date,
         description: action.description,
         observations: action.observations,
         situation_id: action.situation_id,
         updated_at: action.updated_at
      });

      document.getElementById('differenceDays').value = action.difference_days;
   }

   // ENVIA FORMULÁRIO DE CRIAÇÃO OU EDIÇÃO DA AÇÃO
   const sendForm = async () => {
      try {
         const userToken = localStorage.getItem("token");

         let response = {};

         if (!formData.id)
            response = await reqApi("post", "actions", userToken, formData);
         else
            response = await reqApi("patch", `actions/${formData.id}`, userToken, formData);

         errorHandling(response);

         document.getElementById('createAction').click();
         document.getElementById('differenceDays').value = '';

         getOneTarget();
         setFormData({ ...formData, ...defaultData });
      } catch (error) {
         errorHandling(error);
      }
   }

   // EXECUTA OS ALERTAS PARA AS ATIVIDADES A 7 OU MENOS DIAS DO FIM
   const runAlerts = async (actions = target.actions, date_now = "") => {
      if (actions) {
         let rows = [];

         actions.forEach(async (action) => {
            let deteNow = new Date(date_now);
            let dateEnd = new Date(action.conclusion_date);

            // VERIFICANDO A DIFERENÇA DE DIAS ENTRE AS DATAS
            let diffDate = (dateEnd - deteNow) / (24 * 3600000);

            if (diffDate <= 7 && action.situation_id <= 2) {
               let dayText = diffDate <= 1 && diffDate >= -1 ? "dia" : "dias";

               if (Number(action.situation_id) === 2)
                  rows.push([action.title, "Atrasada", "danger", diffDate]);
               else if (diffDate === 0)
                  rows.push([action.title, "Encerra hoje", "warning", diffDate]);
               else
                  rows.push([action.title, `Encerra em ${diffDate} ${dayText}`, "warning", diffDate]);
            }
         });

         rows.sort(sortFunction);

         if (rows.length > 0) {
            let html = newTableForAlerts(["Etapa", "Status"], rows, "As seguintes etapas possuem alguma pendência:");

            await Swal.fire({
               title: 'Atenção!',
               html: html,
               icon: 'warning'
            });
         }
      }
   }

   useEffect(() => {
      getSectorsAndSubSectors();
      getSituations();
      getOneTarget(true);

      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      listSituationsForFiler();

      // eslint-disable-next-line
   }, [listSituations]);

   useEffect(() => {
      const dateStart = new Date(formData.start_date);
      const dateEnd = new Date(formData.conclusion_date);

      // VERIFICANDO A DIFERENÇA DE DIAS ENTRE AS DATAS
      const diffDate = (dateEnd - dateStart) / (24 * 3600000);

      // CASO TENHA SIDO CADASTRADO O INICIO APOS O FIM, A DATA DE FIM RECEBE A DE INICIO
      if (diffDate < 0) handleFormChange('conclusion_date', formData.start_date);

      // ATUALIZAR A INPUT NA DIFERENÇA DE DIAS
      let input = document.querySelector('#differenceDays');
      if (input) input.value = diffDate <= 0 ? 0 : diffDate;

      // VERIFICANDO SE A ATIVIDADE ESTÁ NO TEMPO HÁBIL OU ATRASADA
      checkDifferenceForInputs();

      // eslint-disable-next-line
   }, [formData.start_date, formData.conclusion_date])

   return (
      <ChosenTemplate loading={loading}>
         <TitlePage>Meta: {target?.title}</TitlePage>

         <SectionPage>
            <ButtonRightPage
               text="Nova Etapa"
               icon="fa-plus"
               color="success"
               data-toggle="modal"
               data-target="#createAction"
               id="btnCreateAction"
               onClick={() => {
                  setMode("create");
                  setFormData({ ...formData, ...defaultData })
               }}
            />
         </SectionPage>

         {/* FILTRO DE PESQUISA */}
         <SectionPage>
            <hr />

            <span className="font-weight-bolder">Filtros de Busca:</span>

            <div className="row mt-2">
               <Input
                  type="date"
                  label="Data de Início"
                  value={filterData.start_date}
                  onChange={(e) => handleFormChange("start_date", e.target.value, filterData, setFilterData)}
                  col="lg-4 col-12"
               />

               <Input
                  type="date"
                  label="Data de Conclusão"
                  value={filterData.conclusion_date}
                  onChange={(e) => handleFormChange("conclusion_date", e.target.value, filterData, setFilterData)}
                  col="lg-4 col-12"
               />

               <Select
                  label="Setor Responsável"
                  options={listSectors}
                  value={filterData.sector_id}
                  onChange={(e) => handleFormChange("sector_id", e.target.value, filterData, setFilterData)}
                  col="lg-4 col-12"
                  filterMode={true}
               />

               <div className="col-lg-8 col-sm-12 mb-2">
                  {listSituations.map((e, i) => {
                     const inArray = filterSituations.includes(e.id);

                     return (
                        <Checkbox
                           key={i}
                           text={e.title}
                           checked={inArray}
                           onChange={() => {
                              if (inArray) setFilterSituations(filterSituations.filter(n => n !== e.id));
                              else setFilterSituations([...filterSituations, e.id])
                           }}
                        />
                     )
                  })}
               </div>

               <div className="col-lg-4 col-sm-12 mb-2 text-lg-right text-sm-left">
                  <Button
                     color="primary"
                     icon="fa-search"
                     text="Pesquisar"
                     otherClass="btn-sm"
                     onClick={getOneTarget}
                  />

                  <Button
                     color="danger"
                     icon="fa-eraser"
                     text="Limpar Filtros"
                     otherClass="btn-sm"
                     onClick={clearFields}
                  />
               </div>
            </div>
         </SectionPage>

         {/* TABELA COM A LISTAGEM */}
         <SectionPage>
            <Table
               cols={["Etapa", "Setor Responsável", "Início", "Conclusão", "Dias", "Status", ""]}
               isRows={target?.actions?.length > 0}
            >
               {target?.actions?.map((e, i) => (
                  <RowTable key={i}>
                     <ColTable>{e.title}</ColTable>
                     <ColTable>{e.sector_major ? `${e.sector_major} - ${e.sector}` : e.sector}</ColTable>
                     <ColTable>{dateBrConverter(e.start_date)}</ColTable>
                     <ColTable>{dateBrConverter(e.conclusion_date)}</ColTable>
                     <ColTable>{e.difference_days}</ColTable>
                     <ColTable>
                        <Badge color={e.situation_color}>
                           {e.situation}
                        </Badge>
                     </ColTable>
                     <ColTable>
                        <div className="row">
                           <div className="col text-right" style={{ minWidth: '180px' }}>
                              <Button
                                 color="info"
                                 icon="fa-info"
                                 title="Visualizar"
                                 size="btn-sm"
                                 data-toggle="modal"
                                 data-target="#createAction"
                                 onClick={() => showAction(e)}
                              />

                              <Button
                                 color="warning"
                                 icon="fa-edit"
                                 title="Editar"
                                 size="btn-sm"
                                 data-toggle="modal"
                                 data-target="#createAction"
                                 onClick={() => editAction(e)}
                              />

                              {level === "manager" ?
                                 <Button
                                    color="danger"
                                    icon="fa-times"
                                    title="Remover"
                                    size="btn-sm"
                                    onClick={() => deleteAction(e.id)}
                                 />
                                 : null}
                           </div>
                        </div>
                     </ColTable>
                  </RowTable>
               ))}
            </Table>
         </SectionPage>

         {/* FORMULÁRIO */}
         <ModalBody
            id_modal="createAction"
            title={formData.id ? (mode === "show" ? `Etapa ${formData.situation_title}` : "Editar Etapa") : "Cadastrar Etapa"}
            buttonAction={sendForm}
            disabledAction={!(formData.title && formData.sector_id && formData.start_date && formData.conclusion_date && formData.situation_id && formData.description)}
            hideAction={mode === "show"}
            headerColor={mode === "show" ? formData.situation_color : null}
         >
            <Input
               type="text"
               label="Nome da Etapa"
               value={formData.title}
               onChange={(e) => handleFormChange("title", e.target.value)}
               required={true}
               disabled={mode === "show"}
            />

            <Textarea
               label="Descrição"
               value={formData.description || ""}
               onChange={(e) => handleFormChange("description", e.target.value)}
               required={true}
               disabled={mode === "show"}
               row="2"
            />

            <Select
               label="Setor Responsável"
               options={listSectors}
               optionsAttribute={["id", "title", "sub_sectors"]}
               value={formData.sector_id}
               onChange={(e) => handleFormChange("sector_id", e.target.value)}
               required={true}
               disabled={mode === "show"}
               optgroup={true}
            />

            <Input
               type="date"
               label="Data de Início"
               value={formData.start_date}
               onChange={(e) => !(mode === "edit" && level !== "manager") ? handleFormChange("start_date", e.target.value) : null}
               required={true}
               disabled={mode === "show" || (mode === "edit" && level !== "manager")}
               col="5"
            />

            <Input
               type="text"
               label="Dias"
               onChange={(e) => daysChange(e.target.value)}
               disabled={mode === "show"}
               col="2"
               id="differenceDays"
            />

            <Input
               type="date"
               label="Previsão de Conclusão"
               value={formData.conclusion_date}
               onChange={(e) => handleFormChange('conclusion_date', e.target.value)}
               disabled={mode === "show"}
               required={true}
               col="5"
            />

            {mode !== "show" ?
               <Select
                  label="Status"
                  options={selectSituations}
                  value={formData.situation_id}
                  onChange={(e) => handleFormChange("situation_id", e.target.value)}
                  required={true}
               />
               : null}

            <Textarea
               label="Observações"
               value={formData.observations || ""}
               onChange={(e) => handleFormChange("observations", e.target.value)}
               disabled={mode === "show"}
               row="4"
            />

            {formData.updated_at ? (
               <Span>
                  Atualizado: {moment(formData.updated_at).format('DD/MM/YYYY HH:mm:ss')}
               </Span>
            ) : null}

            {mode !== "show" ? <RequiredMessage /> : null}
         </ModalBody>
      </ChosenTemplate>
   );
}