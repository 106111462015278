import { useState } from "react";

import { Menu } from "./Menu";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";

import { ToastContainer } from "react-toastify";

export const Template = ({ routes, footer = true, loading = false, children }) => {
   const [visibleMenu, setVisibleMenu] = useState(true);
   
   const toogleMenu = () => {
      setVisibleMenu(!visibleMenu);
   }

   return (
      <>
         <div id="wrapper">
            {routes ? <Menu routes={routes} otherClass={!visibleMenu ? 'd-none' : null} /> : null}

            <div id="content-wrapper" className="d-flex flex-column">
               <div id="content">
                  {footer ? <NavBar toogleMenu={toogleMenu} /> : null}

                  <div
                     className="container-fluid p-4"
                     style={{ height: footer ? 'auto' : '100vh', padding: footer ? '1.5rem' : 'auto' }}
                  >
                     <div className={routes ? "bg-white p-4 rounded shadow" : null}>
                        {loading ? 'Carregando...' : children}
                     </div>
                  </div>
               </div>

               {footer ? <Footer /> : null}
            </div>
         </div>

         <ToastContainer />
      </>
   );
}