import { ManagerTemplate } from "./ManagerTemplate";
import BigSicmaLogo from "../../assets/images/logo_sicma_big.png";

export const ManagerHome = () => {
   return (
      <ManagerTemplate>
         <div className="text-center py-5">
            <img src={BigSicmaLogo} alt="Logo do Sicma" style={{ maxWidth: "100%" }} />
            <h2 className="mt-4">Sistema de Cadastro e Monitoramento de Ações</h2>
         </div>
      </ManagerTemplate>
   );
}