import axios from "axios";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}/api`
})

export const reqApi = (method, url, token, data = {}) => axios({
  method,
  url: `${process.env.REACT_APP_URL_API}/api/${url}`,
  headers: { Authorization: `Bearer ${token}` },
  data
});