export const handleFormChange = (field, value, formData, setFormData) => {
   setFormData(Object.assign({}, formData, { [field]: value }));
}

export const checkDate = (date) => {
   return date instanceof Date && !isNaN(date);
}

export const getFunction = (id) => {
   const idNumber = Number(id);

   if (idNumber === 0 || idNumber === 1) return "Gestão";
   else if (idNumber === 2) return "Funcionário";
}

export const dateBrConverter = (date) => {
   return date.split('-').reverse().join('/');
}

export const sortFunction = (a, b) => {
   if (a[3] === b[3])
      return 3;

   return (a[3] < b[3]) ? -1 : 1;
}