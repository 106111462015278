import uuid from 'react-uuid';

export const Textarea = ({ label, col, row = 5, required = false, ...rest }) => {
   const inputId = uuid();

   return (
      <div className={`form-group col-${col || 12}`}>
         {label ? <label htmlFor={inputId}>{label}{required ? "*" : null}</label> : null}

         <textarea
            id={inputId}
            className="form-control"
            rows={row}
            {...rest}
         />
      </div>
   );
}