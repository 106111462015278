import { useState } from "react";
import jwt_decode from 'jwt-decode';
import { reqApi } from "../../lib/axios";

import { SectionPage } from "../../components/Page/SectionPage";
import { TitlePage } from "../../components/Page/TitlePage";
import { Button } from "../../components/Items/Button";
import { Input } from "../../components/Forms/Input";

import { errorHandling } from "../../services/errorHandling";
import { toast } from "react-toastify";

import { ManagerTemplate } from "../Manager/ManagerTemplate";
import { EmployeeTemplate } from "../Employee/EmployeeTemplate";

export const Profile = ({ level }) => {
   const ChosenTemplate = level === "manager" ? ManagerTemplate : EmployeeTemplate;

   const [showPass, setShowPass] = useState(false);
   const [showNewPass, setShowNewPass] = useState(false);
   const [showConfirmPass, setShowConfirmPass] = useState(false);

   const [btnDisabled, setBtnDisabled] = useState(true);
   const [formData, setFormData] = useState({
      name: "",
      password: "",
      confirmPassword: "",
      currentPassword: ""
   });

   const handleFormChange = (field, value) => {
      const newForm = Object.assign({}, formData, { [field]: value })
      setFormData(newForm);

      if (
         !newForm.name ||
         !newForm.currentPassword ||
         (!newForm.password && newForm.confirmPassword) ||
         (newForm.password && !newForm.confirmPassword)
      ) {
         setBtnDisabled(true)
      } else {
         setBtnDisabled(false)
      }
   }

   const getDataUser = async () => {
      try {
         const userToken = localStorage.getItem("token");

         if (userToken) {
            const decodedToken = jwt_decode(userToken);

            setFormData({
               name: decodedToken.name,
               password: "",
               confirmPassword: "",
               currentPassword: ""
            })
         }
      } catch (error) {
         errorHandling(error);
      }
   }

   const sendForm = async () => {
      try {
         const userToken = localStorage.getItem("token");

         const response = await reqApi("patch", "auth/profile", userToken, formData);

         toast.success("Atualizado com sucesso!");

         localStorage.setItem("token", response.data.token);

         setTimeout(() => {
            if (formData.password !== "" && formData.confirmPassword !== "") 
               window.location = '/sair';
            else
               window.location.reload();
         }, 2000)
      } catch (error) {
         errorHandling(error);
      }
   }

   useState(() => {
      getDataUser();
   }, []);

   return (
      <ChosenTemplate>
         <TitlePage>Meu Perfil</TitlePage>

         <SectionPage>
            <div className="row col-lg-6 col-sm-10 mx-auto">
               <Input
                  type="text"
                  label="Nome"
                  value={formData.name}
                  onChange={(e) => handleFormChange("name", e.target.value)}
               />

               <Input
                  type={showNewPass ? "text" : "password"}
                  label="Nova Senha"
                  col="6"
                  value={formData.password}
                  onChange={(e) => handleFormChange("password", e.target.value)}
                  group={true}
                  groupIcon={showNewPass ? "fas fa-eye" : "fas fa-eye-slash"}
                  groupClick={() => setShowNewPass(!showNewPass)}
               />

               <Input
                  type={showConfirmPass ? "text" : "password"}
                  label="Confirmar Senha"
                  col="6"
                  value={formData.confirmPassword}
                  onChange={(e) => handleFormChange("confirmPassword", e.target.value)}
                  group={true}
                  groupIcon={showConfirmPass ? "fas fa-eye" : "fas fa-eye-slash"}
                  groupClick={() => setShowConfirmPass(!showConfirmPass)}
               />

               <Input
                  type={showPass ? "text" : "password"}
                  label="Senha Atual"
                  value={formData.currentPassword}
                  onChange={(e) => handleFormChange("currentPassword", e.target.value)}
                  group={true}
                  groupIcon={showPass ? "fas fa-eye" : "fas fa-eye-slash"}
                  groupClick={() => setShowPass(!showPass)}
               />

               <div className="col-12">
                  <Button
                     text="Salvar Alterações"
                     color="primary"
                     icon="fa-check"
                     otherClass="col-12"
                     onClick={sendForm}
                     disabled={btnDisabled}
                  />
               </div>
            </div>
         </SectionPage>
      </ChosenTemplate>
   );
}