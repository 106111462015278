export const newTableForAlerts = (cols, rows, text) => {
   const tdGen = (cols, tag = 'td') => {
      let data = "";

      cols?.forEach(col => { data += `<${tag}>${col}</${tag}>` });

      return data;
   }

   const rowGen = () => {
      let showRows = "";

      rows?.forEach(row => {
         showRows += `
            <tr>
               <td>${row[0]}</td>
               <td><span class="badge badge-${row[2]}">${row[1]}</span></td>
            </tr>
         `
      });

      return showRows;
   }

   return `
      <p>${text}</p>

      <div style="max-height: 300px; overflow-y: auto;">
         <table class="table table-sm text-left">
            <thead>
               <tr>
                  ${tdGen(cols, 'th')}
               </tr>
            </thead>

            <tbody>
               ${rowGen()}
            </tbody>
         </table>
      </div>
   `;
}