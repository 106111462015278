import { CardLoose } from "../../components/CardLoose";

export const NotFound = () => {
	return (
		<CardLoose>
			<div className="text-center">
				<h1>Página não encontrada</h1>
			</div>
		</CardLoose>
	);
}