export const Footer = () => {
   const year = new Date();

   return (
      <footer className="sticky-footer bg-white">
         <div className="container my-auto">
            <div className="copyright text-center my-auto">
               <span>SiCMA { year.getFullYear() }</span>
            </div>
         </div>
      </footer>
   );
}